exports.components = {
  "component---src-pages-2021-2022-conference-tsx": () => import("./../../../src/pages/2021_2022/conference.tsx" /* webpackChunkName: "component---src-pages-2021-2022-conference-tsx" */),
  "component---src-pages-2021-2022-workshops-tsx": () => import("./../../../src/pages/2021_2022/workshops.tsx" /* webpackChunkName: "component---src-pages-2021-2022-workshops-tsx" */),
  "component---src-pages-2022-2023-conference-tsx": () => import("./../../../src/pages/2022_2023/conference.tsx" /* webpackChunkName: "component---src-pages-2022-2023-conference-tsx" */),
  "component---src-pages-2022-2023-workshops-tsx": () => import("./../../../src/pages/2022_2023/workshops.tsx" /* webpackChunkName: "component---src-pages-2022-2023-workshops-tsx" */),
  "component---src-pages-2023-2024-workshops-tsx": () => import("./../../../src/pages/2023_2024/workshops.tsx" /* webpackChunkName: "component---src-pages-2023-2024-workshops-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-archive-tsx": () => import("./../../../src/pages/archive.tsx" /* webpackChunkName: "component---src-pages-archive-tsx" */),
  "component---src-pages-calendar-tsx": () => import("./../../../src/pages/calendar.tsx" /* webpackChunkName: "component---src-pages-calendar-tsx" */),
  "component---src-pages-conference-tsx": () => import("./../../../src/pages/conference.tsx" /* webpackChunkName: "component---src-pages-conference-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-join-tsx": () => import("./../../../src/pages/join.tsx" /* webpackChunkName: "component---src-pages-join-tsx" */),
  "component---src-pages-nitro-ai-tsx": () => import("./../../../src/pages/nitroAI.tsx" /* webpackChunkName: "component---src-pages-nitro-ai-tsx" */),
  "component---src-pages-nitro-nlp-tsx": () => import("./../../../src/pages/nitroNLP.tsx" /* webpackChunkName: "component---src-pages-nitro-nlp-tsx" */),
  "component---src-pages-rules-tsx": () => import("./../../../src/pages/rules.tsx" /* webpackChunkName: "component---src-pages-rules-tsx" */),
  "component---src-pages-summer-school-tsx": () => import("./../../../src/pages/summer_school.tsx" /* webpackChunkName: "component---src-pages-summer-school-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-pages-workshops-tsx": () => import("./../../../src/pages/workshops.tsx" /* webpackChunkName: "component---src-pages-workshops-tsx" */)
}

